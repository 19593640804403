import React from "react";
import Social from "./Social";

const Home = () => {
  return (
    <>
    
      <div className="tokyo_tm_home">
        <div className="home_content">
          <div className="avatar">
            <div
              className="image avatar_img"
              style={{
                backgroundImage: "url(assets/img/slider/x.jpg)",
              }}
            ></div>
            {/* END AVATAR IMAGE */}
          </div>
          {/* END AVATAR */}
          <div className="details">
            <h3 className="name">Puttipong</h3>
            <p className="job">
            Hello, I'm a Owner of <a href="https://www.youtube.com/channel/UCAL0kuqfrdasnduRf3FOAkA" target="_blank" id="anyone_link"> ANYONE </a> & Assistant Data Analytics Manager at <a href="https://predictive.co.th/" target="_blank" id="anyone_link">Predictive</a> based in Bangkok and happy to say "thank you" for everythings.
            </p>
            {/* END JOB */}
            <Social />
          </div>
          {/* END DETAILS */}
        </div>
        {/* END HOME CONTENT */}
      </div>
      {/* END HOME */}
    </>
  );
};

export default Home;
